export const WORDS = [
  'proof',
  'valid',
  'stark',
  'ether',
  'crypt',
  'solve',
  'coded',
  'rolls',
  'zkpru',
  'alpha',
  'dapps',
  'scale',
  'smart',
  'chain',
  'cairo',
  'solid',
  'range',
  'layer',
  'trust',
  'block',
  'quick',
  'early',
  'state',
  'build',
  'felts',
  'field',
  'param',
  'array',
  'seeds'
]
